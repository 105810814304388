import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Context from '@bibliocommons/context';
import { useRouter } from '@bibliocommons/utils-routing';
import Pagination, { PaginationLabel } from '@bibliocommons/pagination';
import { searchQueryShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';

import makeEventsSearchFilterQuery from 'app/helpers/facets/makeEventsSearchFilterQuery';
import { FACET_FIELDS_ORDER } from 'app/constants/EventsConstants';
import Facets from 'app/components/shared/Facets';
import ActiveFilters from 'app/components/shared/Facets/ActiveFilters';
import EventsViewOptions from 'app/components/events/EventsViewOptions';
import { EventsSearchItemContainer } from './EventsSearchItem';
import EmptyEventsSearch from './EmptyEventsSearch';
import CalloutFeaturedEvent from './CalloutFeaturedEvent';
import EventsSearchHeader from './EventsSearchHeader';

import './EventsSearchPage.scss';

export default function EventsSearchPage({
  activeFilters,
  searchQuery,
  pagination,
  events,
  facetFields,
  isFetching,
  calloutFeaturedEvent,
  singleBranch,
  eventsRSSLink,
  hasFeaturedEvents
}) {
  const { mobileApp } = useContext(Context);
  const router = useRouter();
  const analyticsEntity = {
    search_query: searchQuery.get('q'),
    ui_content_page: searchQuery.get('page'),
    ui_content_total_count: pagination.get('count'),
    ui_content_sort: 'asc'
  };

  // callout featured event is displayed only on page 1 with no active filters applied
  const page = searchQuery.get('page');
  const showCalloutFeaturedEvent =
    searchQuery.filter((val, key) => key !== 'page').isEmpty() && (!page || page === '1') && calloutFeaturedEvent;

  let eventItems = events;
  if (showCalloutFeaturedEvent) {
    eventItems = events.filter(event => event !== calloutFeaturedEvent);
  }

  function handleToggleFilter(fieldId, currentFilter, _subCategory, override = false) {
    router.push({
      query: makeEventsSearchFilterQuery({
        currentQuery: router.query,
        currentFilters: activeFilters,
        updateFieldId: fieldId,
        updateFilter: currentFilter,
        override
      })
    });
  }

  function renderContent() {
    const startDate = searchQuery.get('startDate');
    const endDate = searchQuery.get('endDate');
    const searchTerm = searchQuery.get('q');
    const resultsCount = pagination.get('count');

    return (
      <div className="events-search-results">
        <div className="container">
          <EventsSearchHeader searchTerm={searchTerm} resultsCount={resultsCount} />
          {resultsCount ? (
            <div className="row">
              <div className="col-lg-3 col-xl-3">
                <Facets
                  handleToggleFilter={handleToggleFilter}
                  categories={FACET_FIELDS_ORDER}
                  facetFields={facetFields}
                  activeFilters={activeFilters}
                  totalResults={resultsCount}
                  startDate={startDate}
                  endDate={endDate}
                  filterType="event"
                  singleBranch={singleBranch}
                />
              </div>
              <div className="col-lg-9 col-xl-9">
                <div className="content-wrapper">
                  <ActiveFilters
                    handleToggleFilter={handleToggleFilter}
                    activeFilters={activeFilters}
                    categories={FACET_FIELDS_ORDER}
                    startDate={startDate}
                    endDate={endDate}
                    headingTag="h2"
                    filterType="event"
                  />
                  <ScreenReaderMessage>
                    <Heading tagName="h2">
                      <FormattedMessage id="event_items" />
                    </Heading>
                  </ScreenReaderMessage>
                  <div className="utility-bar">
                    <div className="pagination-label">
                      <PaginationLabel pagination={pagination} autoFocus />
                    </div>
                    <EventsViewOptions
                      eventsRSSLink={eventsRSSLink}
                      hasFeaturedEvents={hasFeaturedEvents}
                      pagination={pagination}
                      showPdfOption
                    />
                  </div>
                  <ul className="result-items">
                    {showCalloutFeaturedEvent && (
                      <li key={calloutFeaturedEvent.get('id')}>
                        <CalloutFeaturedEvent event={calloutFeaturedEvent} />
                      </li>
                    )}
                    {eventItems.map(event => (
                      <li key={event.get('id')}>
                        <EventsSearchItemContainer event={event} analyticsEntity={analyticsEntity} />
                      </li>
                    ))}
                  </ul>
                  <div className="search-bottom">
                    <Pagination
                      showFirstPage
                      showLastPage
                      hideLabel={mobileApp}
                      isFetching={isFetching}
                      pagination={pagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EmptyEventsSearch />
          )}
        </div>
      </div>
    );
  }

  const classNames = cn('cp-events-search-page', {
    'events-search-page--mobile-app': mobileApp
  });

  return <div className={classNames}>{renderContent()}</div>;
}

EventsSearchPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  events: ImmutablePropTypes.list.isRequired,
  calloutFeaturedEvent: ImmutablePropTypes.map,
  facetFields: ImmutablePropTypes.list.isRequired,
  searchQuery: searchQueryShape.isRequired,
  activeFilters: ImmutablePropTypes.map.isRequired,
  pagination: ImmutablePropTypes.map.isRequired,
  singleBranch: PropTypes.bool,
  eventsRSSLink: PropTypes.string.isRequired,
  hasFeaturedEvents: PropTypes.bool.isRequired
};
