import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import './EventFeaturedImage.scss';

export default function EventFeaturedImage({ featuredImage }) {
  return (
    featuredImage && (
      <div className="cp-event-featured-image">
        <img src={featuredImage.get('url')} alt={featuredImage.get('alt')} loading="lazy" />
      </div>
    )
  );
}

EventFeaturedImage.propTypes = {
  featuredImage: ImmutablePropTypes.map
};
