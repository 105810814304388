import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { SecondaryLink } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { PROVIDERS, REGISTRATION_WINDOW_STATUSES } from 'app/constants/EventsConstants';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import { mapEventToEntity } from 'app/helpers/analytics/formatGA4Entity';
import { trackContentClick } from 'app/actions/AnalyticsGa4Actions';

import './EventRegistrationStatus.scss';

const MESSAGE_IDS = {
  REQUIRED: 'registration_required',
  REQUIREDONLINE: 'registration_required_online',
  CLOSED: 'registration_closed',
  FULL: 'registration_full',
  WAITLISTED: 'registration_waitlisted',
  OPENS: 'registration_window_opens_at',
  CLOSES: 'registration_window_closes_at'
};

export default function EventRegistrationStatus({ event, analyticsEntity }) {
  const dispatch = useDispatch();
  const eventSeries = useSelector(selectEventSeriesEntities);
  const registrationInfo = event.getIn(['definition', 'registrationInfo']);
  const provider = registrationInfo.get('provider');
  const registrationWindow = event.get('registrationWindow');
  const registrationStatus = event.getIn(['registrationWindow', 'status']);
  const isFull = event.get('isFull');
  const registrationClosed = event.get('registrationClosed');
  const waitlistEnabled = registrationInfo.get('waitlistEnabled');
  const isWaitlistActive = registrationClosed && waitlistEnabled;
  const eventTitle = event.getIn(['definition', 'title']);
  const eventDate = moment(event.getIn(['definition', 'start'])).format('MM-DD-YYYY');
  const isCancelled = event.getIn(['definition', 'isCancelled']);

  function isPast() {
    const indexEnd = moment(event.get('indexEnd'));
    const now = moment();
    return indexEnd.isBefore(now);
  }

  // GA4 content click event
  function handleRegisterLink(waitlist = false) {
    const search = {};
    const ui = {
      ui_container_type: 'item',
      ui_component_type: 'link-text',
      ui_component_label: 'Register for event',
      ui_content_layout: 'medium',
      ui_content_page: analyticsEntity?.ui_content_page,
      ui_content_total_count: analyticsEntity?.ui_content_total_count
    };
    if (waitlist) {
      ui.ui_component_label = 'Join waitlist';
    }
    const eventEntity = mapEventToEntity({ event, series: eventSeries.get(event.get('seriesId')) });
    if (analyticsEntity.search_query) {
      search.search_query = analyticsEntity?.search_query;
      search.search_origin = 'user-query';
      search.search_type = 'keyword';
    }
    dispatch(trackContentClick({ event: eventEntity, ui, search }));
  }

  function renderRegisterLink() {
    return (
      <SecondaryLink
        className="register-link"
        href={`/events/${event.get('id')}`}
        dataKey="registration-link"
        handleClick={() => handleRegisterLink(false)}
      >
        <FormattedMessage id="register_for_event" />
      </SecondaryLink>
    );
  }
  function getRegistrationStatus() {
    if (isPast() || isCancelled) {
      return null;
    }

    switch (provider) {
      case PROVIDERS.EXTERNAL: {
        if (isFull) {
          return (
            <span className="registration-disabled">
              <FormattedMessage id={MESSAGE_IDS.FULL} />
            </span>
          );
        }
        return (
          <>
            <ScreenReaderMessage>
              <FormattedMessage id="sr_register_for_event" values={{ eventTitle, eventDate }} />
            </ScreenReaderMessage>
            <span className="registration-active">
              <FormattedMessage id={MESSAGE_IDS.REQUIRED} />
              {renderRegisterLink()}
            </span>
          </>
        );
      }

      case PROVIDERS.BIBLIO_EVENTS: {
        const spotsAvailable = registrationInfo?.get('cap') - event.get('numberRegistered');
        const eventStart = moment(event.getIn(['definition', 'start']));
        const registrationEnd = moment(registrationWindow?.get('windowEnd'));
        const windowEnd = moment(registrationWindow?.get('windowEnd'));
        const windowStart = moment(registrationWindow?.get('windowStart'));
        const windowStartDate = windowStart?.format('MMMM D');
        const windowStartTime = windowStart?.format('h:mma');
        const windowEndDate = windowEnd?.format('MMMM D');
        const windowEndTime = windowEnd?.format('h:mma');

        if (registrationStatus === REGISTRATION_WINDOW_STATUSES.ENDED) {
          return (
            <span className="registration-disabled">
              <FormattedMessage id={MESSAGE_IDS.CLOSED} />
            </span>
          );
        }

        if (registrationStatus === REGISTRATION_WINDOW_STATUSES.UPCOMING) {
          if (isWaitlistActive) {
            return (
              <>
                <ScreenReaderMessage>
                  <FormattedMessage id="sr_register_for_event" values={{ eventTitle, eventDate }} />
                </ScreenReaderMessage>
                <span className="registration-active">
                  <FormattedMessage id={MESSAGE_IDS.REQUIREDONLINE} values={{ numSpots: spotsAvailable }} />
                  {renderRegisterLink()}
                </span>
              </>
            );
          }

          if (registrationWindow.get('windowStart')) {
            return (
              <span className="registration-inactive">
                <FormattedMessage id={MESSAGE_IDS.OPENS} values={{ date: windowStartDate, time: windowStartTime }} />
              </span>
            );
          }
        }

        if (registrationStatus === REGISTRATION_WINDOW_STATUSES.ACTIVE) {
          if (isFull && !isWaitlistActive) {
            return (
              <span className="registration-disabled">
                <FormattedMessage id={MESSAGE_IDS.FULL} />
              </span>
            );
          }

          if ((isFull && isWaitlistActive) || registrationClosed) {
            return (
              <>
                <ScreenReaderMessage>
                  <FormattedMessage id="sr_join_waitlist" values={{ eventTitle, eventDate }} />
                </ScreenReaderMessage>
                <span className="registration-waitlist">
                  <FormattedMessage id={MESSAGE_IDS.WAITLISTED} />
                  <SecondaryLink
                    className="register-link"
                    href={`/events/${event.get('id')}`}
                    dataKey="registration-link"
                    handleClick={() => handleRegisterLink(true)}
                  >
                    <FormattedMessage id="join_waitlist" />
                  </SecondaryLink>
                </span>
              </>
            );
          }

          if (!isFull && !isWaitlistActive && registrationEnd) {
            if (registrationEnd.isBefore(eventStart)) {
              return (
                <>
                  <ScreenReaderMessage>
                    <FormattedMessage id="sr_register_for_event" values={{ eventTitle, eventDate }} />
                  </ScreenReaderMessage>
                  <span className="registration-active">
                    <FormattedMessage
                      id={MESSAGE_IDS.CLOSES}
                      values={{ date: windowEndDate, time: windowEndTime, numSpots: spotsAvailable }}
                    />
                    {renderRegisterLink()}
                  </span>
                </>
              );
            }
            return (
              <>
                <ScreenReaderMessage>
                  <FormattedMessage id="sr_register_for_event" values={{ eventTitle, eventDate }} />
                </ScreenReaderMessage>
                <span className="registration-active">
                  <FormattedMessage id={MESSAGE_IDS.REQUIREDONLINE} values={{ numSpots: spotsAvailable }} />
                  {renderRegisterLink()}
                </span>
              </>
            );
          }
        }
        return null;
      }
      default:
        return null;
    }
  }

  const message = getRegistrationStatus();
  return message ? <div className="cp-registration-status">{message}</div> : null;
}

EventRegistrationStatus.propTypes = {
  event: ImmutablePropTypes.map.isRequired,
  analyticsEntity: PropTypes.object
};
