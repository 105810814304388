import Immutable from 'immutable';

export default function getToggledFilter({ fieldId, currentFilter, activeFilters, separator, prefix = '', override }) {
  const queryField = `${prefix}${fieldId}`;
  const filterValue = currentFilter.get('value');
  const activeValues = activeFilters.get(fieldId, Immutable.List()).map(f => f.get('value'));
  let newValues;
  if (override) {
    newValues = filterValue ? Immutable.List([filterValue]) : Immutable.List();
  } else {
    newValues = activeValues.includes(filterValue)
      ? activeValues.filter(val => val !== filterValue)
      : activeValues.push(filterValue);
  }
  return {
    [queryField]: newValues.size > 0 ? newValues.join(separator) : undefined
  };
}
