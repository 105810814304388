import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import EventsSearchItemContainer from '../EventsSearchItem/EventsSearchItemContainer';

import './CalloutFeaturedEvent.scss';

export default function CalloutFeaturedEvent({ event }) {
  return (
    <div className="cp-callout-featured-event">
      <EventsSearchItemContainer event={event} isCalloutFeatured />
    </div>
  );
}

CalloutFeaturedEvent.propTypes = {
  event: ImmutablePropTypes.map.isRequired
};
