import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIntl } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from '@bibliocommons/utils-routing';
import ProgressBar from '@bibliocommons/shared-progress-bar';
import { searchQueryShape } from '@bibliocommons/bc-prop-types';
import DrawerActions from 'app/actions/DrawerActions';
import {
  selectSearchResults,
  selectCalloutFeaturedEvent,
  selectSearchFacets,
  selectSearchQuery,
  selectActiveFilters,
  selectPagination,
  selectEventsRSSLink
} from 'app/selectors/events/EventsSearchSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import EventsSearchPage from './EventsSearchPage';

export function EventsSearchPageContainer(props) {
  const intl = useIntl();

  return (
    <div>
      <DocumentHeadContainer pageTitle={intl.formatMessage({ id: 'events_search_page_title' })} />
      <ProgressBar active={props.isFetching} />
      <EventsSearchPage {...props} />
    </div>
  );
}

EventsSearchPageContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  drawer: ImmutablePropTypes.mapContains({
    open: PropTypes.bool
  }).isRequired,
  events: ImmutablePropTypes.list.isRequired,
  calloutFeaturedEvent: ImmutablePropTypes.map,
  searchQuery: searchQueryShape.isRequired,
  activeFilters: ImmutablePropTypes.map.isRequired,
  pagination: ImmutablePropTypes.map.isRequired,
  facetFields: ImmutablePropTypes.list.isRequired,
  drawerActions: PropTypes.objectOf(PropTypes.func).isRequired,
  singleBranch: PropTypes.bool,
  eventsRSSLink: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const library = selectCurrentLibrary(state);

  return {
    isFetching: state.getIn(['events', 'eventsSearch', 'isFetching'], false),
    drawer: state.get('drawer'),
    events: selectSearchResults(state),
    calloutFeaturedEvent: selectCalloutFeaturedEvent(state),
    facetFields: selectSearchFacets(state),
    searchQuery: selectSearchQuery(state),
    activeFilters: selectActiveFilters(state),
    pagination: selectPagination(state),
    singleBranch: library.get('singleBranch'),
    eventsRSSLink: selectEventsRSSLink(state),
    hasFeaturedEvents: state.getIn(['events', 'eventsSearch', 'hasFeaturedEvents'], false)
  };
};

const mapDispatchToProps = dispatch => ({
  drawerActions: bindActionCreators(DrawerActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsSearchPageContainer));
