import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import Context from '@bibliocommons/context';
import { useRouter } from '@bibliocommons/utils-routing';
import Heading from '@bibliocommons/heading';
import ClearFacets from 'app/components/shared/Facets/ClearFacets';

import './EventsSearchHeader.scss';

export default function EventsSearchHeader({ searchTerm, resultsCount }) {
  const { query } = useRouter();
  const { mobileApp } = useContext(Context);
  const eventSeries = useSelector(selectEventSeriesEntities);

  if (mobileApp && !query.series) return null;

  function renderTitle() {
    if (query.series) {
      const seriesTitle = eventSeries.getIn([query.series, 'definition', 'title']);
      return <FormattedMessage id="events_search_series_title" values={{ seriesTitle }} />;
    }

    if (searchTerm) {
      return <FormattedMessage id="events_search_term" values={{ term: searchTerm }} />;
    }

    return <FormattedMessage id="events_search_page_title" />;
  }

  function renderClearFacets() {
    if ((searchTerm && !!resultsCount) || !!query.series) {
      return (
        <ClearFacets>
          <FormattedMessage id="explore_all_events" />
        </ClearFacets>
      );
    }
    return null;
  }

  const classNames = cn('cp-search-header', {
    'search-header--search-term': !!searchTerm,
    'search-header--mobile-app': !!mobileApp
  });

  return (
    <div className={classNames}>
      <Heading className="page-heading" tagName="h1" size="large" variant>
        {renderTitle()}
      </Heading>
      {renderClearFacets()}
    </div>
  );
}

EventsSearchHeader.propTypes = {
  searchTerm: PropTypes.string,
  resultsCount: PropTypes.number
};
