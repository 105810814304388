import React from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { useRouter } from '@bibliocommons/utils-routing';
import { Link } from '@bibliocommons/base-links';
import Popover from '@bibliocommons/popover';

import './EventTaxonomies.scss';

export default function EventTaxonomies({ program, types, audiences, languages }) {
  const router = useRouter();

  function renderTaxonomy(fieldId, taxonomy) {
    const taxonomyId = taxonomy.get('id');
    const taxonomyName = taxonomy.get('name');
    const href = router.createHref({ query: { [fieldId]: taxonomyId } });

    return (
      <li key={taxonomyId}>
        <Link
          preventDefault
          dataKey="event-taxonomy-link"
          href={href}
          aria-current={!!router.query[fieldId]?.includes(taxonomyId)}
          handleClick={() => router.push(href)}
        >
          <span aria-hidden>{taxonomyName}</span>
          <ScreenReaderMessage>
            <FormattedMessage id="sr_taxonomy" values={{ taxonomyName }} />
          </ScreenReaderMessage>
        </Link>
      </li>
    );
  }

  const allTaxonomies = Immutable.List([
    program ? renderTaxonomy('programs', program) : null,
    types.map(type => renderTaxonomy('types', type)),
    audiences.map(audience => renderTaxonomy('audiences', audience)),
    languages.map(language => renderTaxonomy('languages', language))
  ])
    .flatten()
    .filter(taxonomy => !!taxonomy);

  const displayedTaxonomies = allTaxonomies.slice(0, 5);
  const moreTaxonomies = allTaxonomies.slice(5, allTaxonomies.size - 1);

  function renderPopoverContent() {
    return (
      <div className="popover-contents">
        <ul>{moreTaxonomies}</ul>
      </div>
    );
  }

  return (
    !!allTaxonomies.size && (
      <div className="cp-event-taxonomies">
        <ul>{displayedTaxonomies}</ul>
        {!!moreTaxonomies.size && (
          <Popover dataKey="more-taxonomies-container" renderContent={renderPopoverContent}>
            <Link dataKey="more-taxonomies-link" href="#more-taxonomies" preventDefault>
              <span aria-hidden>
                <FormattedMessage id="more_taxonomies" values={{ count: moreTaxonomies.size }} />
              </span>
              <ScreenReaderMessage>
                <FormattedMessage id="sr_more_taxonomies" values={{ count: moreTaxonomies.size }} />
              </ScreenReaderMessage>
            </Link>
          </Popover>
        )}
      </div>
    )
  );
}

EventTaxonomies.propTypes = {
  program: ImmutablePropTypes.map,
  types: ImmutablePropTypes.list.isRequired,
  audiences: ImmutablePropTypes.list.isRequired,
  languages: ImmutablePropTypes.list.isRequired
};
