import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { stripTags, truncate } from '@bibliocommons/utils-html';

export default function EventDescription({ event }) {
  const strippedDesc = stripTags(event.getIn(['definition', 'description'], ''), '<p>');
  const truncatedDesc = truncate(strippedDesc, 240);

  return (
    truncatedDesc && (
      <div className="cp-event-description">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: truncatedDesc }} />
      </div>
    )
  );
}

EventDescription.propTypes = {
  event: ImmutablePropTypes.map.isRequired
};
