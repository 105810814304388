import React from 'react';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { useRouter } from '@bibliocommons/utils-routing';
import { SecondaryLink } from '@bibliocommons/base-links';

import EventDateTime from 'app/components/shared/EventDateTime';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import getMomentAtLocation from 'app/helpers/locations/getMomentAtLocation';

import './EventDate.scss';

export default function EventDate({ event, location }) {
  const router = useRouter();
  const eventSeries = useSelector(selectEventSeriesEntities);
  const eventStart = event.getIn(['definition', 'start']);
  const eventEnd = event.getIn(['definition', 'end']);
  const isRecurring = event.get('isRecurring');
  const href = router.createHref({ query: { series: event.get('seriesId') } });

  function renderAllDatesLink() {
    if (!isRecurring || router.query.series || !event?.size) return null;

    function handleViewAllDates() {
      router.push(href);
    }

    const currentDateAtlocation = getMomentAtLocation(location) || moment();
    const allSeriesDates = eventSeries.getIn([event.get('seriesId'), 'eventKeys']);
    const upcomingSeriesDates = allSeriesDates?.filter(date => {
      const dateAtlocation = getMomentAtLocation(location, date) || moment(date);
      return dateAtlocation.isSameOrAfter(currentDateAtlocation, 'minute');
    });

    return upcomingSeriesDates?.size > 1 ? (
      <SecondaryLink
        dataKey="all-dates"
        className="all-dates"
        href={href}
        handleClick={handleViewAllDates}
        preventDefault
      >
        <span aria-hidden>
          <FormattedMessage id="all_dates" />
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_all_dates" values={{ eventTitle: event.getIn(['definition', 'title']) }} />
        </ScreenReaderMessage>
      </SecondaryLink>
    ) : null;
  }

  return (
    <div className="cp-event-date">
      <EventDateTime start={eventStart} end={eventEnd} />
      {renderAllDatesLink()}
    </div>
  );
}

EventDate.propTypes = {
  event: ImmutablePropTypes.map.isRequired,
  location: ImmutablePropTypes.map
};
