import React from 'react';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import { useRouter } from '@bibliocommons/utils-routing';
import { TextButton } from '@bibliocommons/base-buttons';

export default function ClearFacets({ children }) {
  const router = useRouter();

  return (
    <TextButton
      className="cp-clear-facets"
      handleClick={() => router.replace({ query: null })}
      dataKey="clear-facets-button"
      leftAligned
    >
      {children}
    </TextButton>
  );
}

ClearFacets.propTypes = {
  children: childrenShape
};
