import moment from 'moment';

import { FILTER_SEPARATOR } from 'app/constants/EventsConstants';
import getToggledFilter from 'app/helpers/facets/getToggledFilter';
import formatFiltersForQuery from 'app/helpers/facets/formatFiltersForQuery';

export default function makeEventsSearchFilterQuery({
  currentQuery,
  currentFilters,
  updateFieldId,
  updateFilter,
  override
}) {
  const prefix = '';
  const separator = FILTER_SEPARATOR;

  const query = {
    ...currentQuery,
    ...formatFiltersForQuery(currentFilters, separator),
    ...getToggledFilter({
      fieldId: updateFieldId,
      currentFilter: updateFilter,
      activeFilters: currentFilters,
      separator,
      prefix,
      override
    })
  };

  delete query.page;

  if (updateFieldId === 'startDate') {
    if (query.startDate && query.endDate && moment(query.startDate).isAfter(moment(query.endDate))) {
      delete query.endDate;
    }
  } else if (updateFieldId === 'endDate') {
    if (query.startDate && query.endDate && moment(query.endDate).isBefore(moment(query.startDate))) {
      delete query.startDate;
    }
  }

  return query;
}
