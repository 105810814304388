import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '@bibliocommons/heading';

import ClearFacets from 'app/components/shared/Facets/ClearFacets';

import './EmptyEventsSearch.scss';

export default function EmptyEventsSearch() {
  return (
    <div className="cp-empty-events-search">
      <Heading tagName="h2" size="medium">
        <FormattedMessage id="empty_events_search_heading" />
      </Heading>
      <FormattedMessage id="empty_events_search_description" tagName="p" />
      <ClearFacets>
        <FormattedMessage id="explore_all_events" />
      </ClearFacets>
    </div>
  );
}
